import React, { Component } from "react";
import { FacebookProvider, LoginButton } from "react-facebook";

class BSPComponent extends Component {
  render() {
    return (
      <FacebookProvider appId="391390192944672">
        <LoginButton
          scope="business_management,whatsapp_business_management,whatsapp_business_messaging"
          onSuccess={data => console.log(data)}
          onError={error => console.log(error)}
        >
          Login via Facebook
        </LoginButton>
      </FacebookProvider>
    );
  }
}

export default BSPComponent;
