//import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeContainer from "./home/HomeContainer";
import BSPContainer from "./home/BSPContainer";
import React, { useEffect, useState, useCallback } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { useAuth0 } from "@auth0/auth0-react";
import loader from "./loader";

const App = ({ children }) => {
  // Get all necessary auth0 data
  const {
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0();

  console.log("===");
  console.log({
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  });

  // Force to work only for logged in users bye checking isAuthenticated
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // Redirect not logged users
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, isLoading]);

  if (error) {
    return <span>{error.message}</span>;
  }

  // show loading indicator while loading
  if (isLoading || !isAuthenticated) {
    return (
      <Player
        autoplay
        loop
        src={loader}
        style={{
          height: "200px",
          width: "200px",
          position: "absolute",
          top: "calc(50vh - 100px)",
          left: "calc(50vw - 100px)",
        }}
      />
    );
  }

  return (
    <Router>
      <div class="fill">
        <Route exact path="/" component={HomeContainer} />
        <Route path="/home" component={HomeContainer} />
        <Route path="/bsp" component={BSPContainer} />
      </div>
    </Router>
  );
};

export default App;
