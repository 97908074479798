import { connect } from 'react-redux';
import BSPComponent from './BSPComponent';
import { operations } from './duck';

const mapStateToProps = (state) => {
  const { selectedSidebarSection, onLoading } = state.home;
  const selectedTitle = selectedSidebarSection;
  return { selectedTitle, onLoading }
};

const mapDispatchToProps = dispatch => {
  const getCompanies = () => dispatch(operations.getCompanies());
  return { getCompanies }
};

const BSPContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BSPComponent);

export default BSPContainer;